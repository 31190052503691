import { useTheme } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { Button, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import { format } from 'date-fns';
import * as React from 'react';
import { assignRecordToAdmin,getPdf } from '../../../services/AdminServices';

import UserDataPDF from './UserDataPDF';


export default function UserData(props) {
    const { open, setOpen, selectedRow, scheme, eSignData, paymentData, mandateData } = props;
    const theme = useTheme();
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pdf, setPdf] = React.useState(null);
    const [showPDFView, setShowPDFView] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [status, setStatus] = React.useState('');
    const [comments, setComments] = React.useState('');
    const [disabled, setDisabled] = React.useState(false);
    const [trancheData, setTrancheData] = React.useState("");



    const handleClose = async () => {
        await assignRecordToAdmin(selectedRow?.detailId, null)
        setOpen(false);
    };

    const handleCloseKeepAssigned = async () => {
       // await assignRecordToAdmin(selectedRow?.detailId, null)
        setOpen(false);
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    React.useEffect(() => {
        if (selectedRow?.schemeDetails?.tranche) {
            let trancheDetails = "";
            Object.entries(selectedRow?.schemeDetails?.tranceDetails).map(item => {

                (months.filter((obj) => {
                    if (obj.value === item[0]) {
                        const str = obj.value + ":" +
                            item[1];
                        trancheDetails = trancheDetails + str + " "
                    }

                }))
            })
            setTrancheData(JSON.stringify(selectedRow?.schemeDetails?.tranceDetails));
        }
    })

    const months = [
        { value: "0", label: 'January' },
        { value: "1", label: 'Feburary' },
        { value: "2", label: 'March' },
        { value: "3", label: 'April' },
        { value: "4", label: 'May' },
        { value: "5", label: 'June' },
        { value: "6", label: 'July' },
        { value: "7", label: 'Agust' },
        { value: "8", label: 'September' },
        { value: "9", label: 'October' },
        { value: "10", label: 'November' },
        { value: "11", label: 'December' },
    ]

    const columns = [
        { value: selectedRow?.instituteType?.instituteTypeName, label: 'Institute Type' },
        { value: selectedRow?.instituteName?.instituteName, label: 'Institute Name' },
        { value: selectedRow?.instituteAddress?.instituteAddress, label: 'Institute Address' },
        { value: selectedRow?.userFcpId ? selectedRow?.userFcpId : "NA", label: 'FCP ID' },
        { value: selectedRow?.salesPersonDetails?.salesPersonId ? selectedRow?.salesPersonDetails?.salesPersonId : "NA", label: 'Sales Person ID' },
        { value: selectedRow?.instituteCourse?.instituteCourses, label: 'Institute Course' },
        { value: selectedRow?.instituteCourse?.courseDuration, label: 'Course Duration' },
        { value: selectedRow?.loanAmount, label: 'Loan Amount' },
        { value: selectedRow?.loanTenure, label: 'Loan Tenure' },
        { value: selectedRow?.schemeDetails?.numberOfAdvanceEmis, label: 'Number Of Advance Emis' },
        { value: selectedRow?.emiAmount, label: 'EMI Amount' },
        { value: selectedRow?.processingFees, label: 'Processing Fees' },
        { value: selectedRow?.schemeDetails?.schemeSubvention, label: 'Scheme Subvention' },
        { value: selectedRow?.schemeDetails?.schemeRoi, label: 'Scheme ROI' },
        { value: eSignData.userEstampDocID, label: 'User Aggrement Document Id' },
        { value: paymentData?.paymentEasepayid, label: 'EasePay Payment ID' },
        { value: mandateData?.mandateId, label: 'User Mandate Id' },
        { value: selectedRow?.schemeDetails?.schemeRoi, label: 'Scheme ROI' },
        { value: selectedRow?.schemeDetails?.tranche ? trancheData : 'NA', label: 'Scheme Tranche Details' },
        { value: selectedRow?.studentDetails?.studentName, label: 'Student Name' },
        { value: format(new Date(selectedRow?.studentDetails?.studentDob), "dd-MM-yyyy"), label: 'Student DOB' },
        { value: selectedRow?.studentDetails?.enrollmentType?.valueDesc, label: 'Enrollment Type' },
        { value: selectedRow?.studentDetails?.studentUniqueId, label: 'Unique Student ID' },
        { value: selectedRow?.studentDetails?.relationshipType?.valueDesc, label: 'Relationship Type' },
        { value: selectedRow?.studentDetails?.demandLetterUploadId ? selectedRow?.studentDetails?.demandLetterUploadId : "NA", label: 'Demand Letter', pdf: selectedRow?.studentDetails?.demandLetterUploadId ? true : false },
        { value: selectedRow?.userPersonalDetails?.maritalStatus?.valueDesc, label: 'Maritial Status' },
        { value: selectedRow?.userPersonalDetails?.noOfDependent?.valueDesc, label: 'Number of Dependents' },
        { value: selectedRow?.userPersonalDetails?.noOfEarningMembers?.valueDesc, label: 'Number of Earning Members' },
        { value: selectedRow?.userPersonalDetails?.monthlyFamilyIncome, label: 'Monthly Family Income' },
        { value: selectedRow?.userPersonalDetails?.noOfEmiServing?.valueDesc, label: 'Number of EMI currently Serving' },
        { value: selectedRow?.userPersonalDetails?.totalEmiAMount, label: 'Total EMI Amount' },
        { value: selectedRow?.userPersonalDetails?.employementType?.valueDesc, label: 'Employment Type' },


    ];

    const columns1 = [
        { value: selectedRow?.userPersonalDetails?.componyName, label: 'Company/Business Name' },
        { value: selectedRow?.userPersonalDetails?.yearsOfCurrentEmployement?.valueDesc, label: 'Years at Current Company/Business' },
        { value: selectedRow?.userPersonalDetails?.totalYearsOfEmployement?.valueDesc, label: 'Total Years Of Employment' },
        { value: selectedRow?.userPanCardNumber, label: 'PAN Card Number' },
        { value: selectedRow?.nameAsPerPan, label: 'Name As Per PAN' },
        { value: selectedRow?.fatherNameAsPerPan, label: 'Father Name as per PAN' },
        { value: selectedRow?.aadharDetails?.aadharId, label: 'Aadhaar ID' },
        { value: selectedRow?.aadharDetails?.userFullName, label: 'Name as per Aadhaar' },
        { value: selectedRow?.aadharDetails?.userAddress, label: 'Aadhar Address' },
        { value: selectedRow?.aadharDetails?.userGender, label: 'Gender' },
        { value: selectedRow?.aadharDetails?.userDob, label: 'DOB' },
        { value: selectedRow?.aadharDetails?.userPincode, label: 'PIN Code' },
        { value: selectedRow?.aadharDetails?.userCity, label: 'City' },
        { value: selectedRow?.aadharDetails?.userDistrict, label: 'District' },
        { value: selectedRow?.aadharDetails?.userState, label: 'State' },
        { value: selectedRow?.aadharDetails?.userResidentialStatus?.valueDesc, label: 'Residential Status' },
        { value: selectedRow?.aadharDetails?.userYearsAtCurrentResident?.valueDesc, label: 'Number of Years at Current Residence' },
        { value: selectedRow?.aadharDetails?.userEmail, label: 'Email' },
        {
            value: selectedRow?.userAddtionalAddressDetails ? selectedRow?.userAddtionalAddressDetails?.addtionalAddressLine1 + ', ' +
                selectedRow?.userAddtionalAddressDetails?.addtionalAddressLine2 + ', ' +
                selectedRow?.userAddtionalAddressDetails?.addtionalAddressLine3 : "NA", label: 'Other Address'
        },
        // { value: selectedRow?.userAddtionalAddressDetails?.addtionalAddressLine2, label: 'Address Line 2' },
        // { value: selectedRow?.userAddtionalAddressDetails?.addtionalAddressLine3, label: 'Address Line 3' },
        { value: selectedRow?.userAddtionalAddressDetails ? selectedRow?.userAddtionalAddressDetails?.addtionalAddressPincode : "NA", label: 'PIN code' },
        { value: selectedRow?.userAddtionalAddressDetails ? selectedRow?.userAddtionalAddressDetails?.addtionalAddressCity : "NA", label: 'City' },
        { value: selectedRow?.userAddtionalAddressDetails ? selectedRow?.userAddtionalAddressDetails?.addtionalAddressDistrict : "NA", label: 'District' },
        { value: selectedRow?.userAddtionalAddressDetails ? selectedRow?.userAddtionalAddressDetails?.addtionalAddressState : "NA", label: 'State' },
        { value: selectedRow?.userAddtionalAddressDetails ? selectedRow?.userAddtionalAddressDetails?.addtionalAddressDocumentId : "NA", label: 'Proof of Address', pdf: selectedRow?.userAddtionalAddressDetails ? true : false },
        { value: selectedRow?.userBankStatementDetails?.statementDetailPathId, label: 'Bank Statement', pdf: true },
        { value: selectedRow?.userBankStatementDetails?.statementDetailPassword, label: 'Password' },
        { value: selectedRow?.userBankStatementDetails?.uploadedDocID, label: 'Cart BI Doc Id' },
        { value: selectedRow?.equifaxPdf === "NA" || !selectedRow?.equifaxPdf ? "No Credit History Available" : selectedRow?.equifaxPdf, label: 'Equifax Statement', pdf: selectedRow?.equifaxPdf === "NA" || !selectedRow?.equifaxPdf ? false : true },
        { value: selectedRow?.loanApplicationPdf, label: 'Application Form', pdf: true },
        { value: selectedRow?.docsUploadDropdownUrl1, label: 'Admin Document 1', pdf: true },
        { value: selectedRow?.docsUploadDropdownUrl2, label: 'Admin Document 2', pdf: true },
    ]
    async function getUserPdf(url, e) {
        e?.preventDefault()
        const splitFile = JSON.stringify(url).split("/")
        const obj = {
            fileName: url
        }
        const dFile = await getPdf(obj);
        setShowPDFView(true)
        const blob = base64ToBlob( dFile?.data.split(',')[1], 'application/pdf' );
        const pdfurl = URL.createObjectURL( blob );
        setPdf(pdfurl);

    }

    function base64ToBlob( base64, type = "application/octet-stream" ) {
        const binStr = atob( base64 );
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[ i ] = binStr.charCodeAt( i );
        }
        return new Blob( [ arr ], { type: type } );
      }

    React.useEffect(() => {
        setComments(selectedRow?.comments)
        if ((selectedRow?.userFormStatus === 'Approved' || selectedRow?.userFormStatus === 'Approved-Manual' || selectedRow?.userFormStatus === 'Rejected' || selectedRow?.userFormStatus === 'Rejected-Manual' || selectedRow?.userFormStatus === 'RFD'))
            setDisabled(true)
        else
            setDisabled(false)
    }, [selectedRow])





    async function handleClick() {
        //await finalSubmit(selectedRow?.detailId, status, comments)
        setOpenAlert(false)
    }

    function actionHandler(appStatus) {
        setStatus(appStatus);
        setOpenAlert(true);
    }

    return (
        <React.Fragment>

            <AppBar sx={{ backgroundColor: theme.palette.headerColor, position: 'relative' }}>
                <Toolbar>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "20px" }}>
                <Button
                                type="button"
                                sx={{ fontStyle: "Lato" }}
                                color="neutral"
                                variant="contained"
                                onClick={handleClose}>
                                Close & unassign
                            </Button>
                            <Button
                                type="button"
                                sx={{ fontStyle: "Lato" }}
                                color="neutral"
                                variant="contained"
                                onClick={handleCloseKeepAssigned}>
                                Close & Keep Assigned
                            </Button>
                            </div>
                </Toolbar>
            </AppBar>

            <DialogContent>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>User Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6}>
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table>
                                            <TableBody>
                                                {columns.map((col) => {
                                                    return (
                                                        <TableRow >

                                                            <TableCell>
                                                                {col?.label}
                                                            </TableCell>
                                                            {col?.pdf ? <TableCell>
                                                                <IconButton
                                                                    edge="start"
                                                                    color="inherit"
                                                                    onClick={(e) => getUserPdf(col?.value, e)}
                                                                    aria-label="PDF"
                                                                >
                                                                    <PictureAsPdf />
                                                                </IconButton>
                                                            </TableCell> : <TableCell >
                                                                {col?.value}
                                                            </TableCell>}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={6}>
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table>
                                            <TableBody>
                                                {columns1.map((col) => {
                                                    return (
                                                        <TableRow >

                                                            <TableCell>
                                                                {col?.label}
                                                            </TableCell>
                                                            {col?.pdf ? <TableCell>
                                                                <IconButton
                                                                    edge="start"
                                                                    color="inherit"
                                                                    onClick={(e) => getUserPdf(col?.value, e)}
                                                                    aria-label="PDF"
                                                                >
                                                                    <PictureAsPdf />
                                                                </IconButton>
                                                            </TableCell> : <TableCell >
                                                                {col?.value}
                                                            </TableCell>}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                            {/* <Grid xs={6} sx={{ py: 3, pr: 4 }} container justifyContent="flex-start" spacing={4}>
                            <Grid item xs={6} sx={{ display: "flex", gap: "20px" }}>
                                <div style={{ width: "230px", padding: "4px" }}>
                                    <CTextField placeholder='Comments' label='Comments' value={comments} onChange={setComments}></CTextField>
                                </div>
                            </Grid>
                        </Grid> */}
                            {/* <Grid sx={{ py: 3, pr: 4 }} container justifyContent="flex-start" >
                                <div style={{ display: "flex", gap: "20px", alignItems: "end" }}>
                                    <div style={{ padding: "4px" }}>
                                        <CTextField placeholder='Comments' label='Comments' value={comments} disabled={selectedRow?.comments || selectedRow?.userFormStatus === 'Approved' || selectedRow?.userFormStatus === 'Rejected'} onChange={setComments}></CTextField>
                                    </div>
                                    <div style={{ padding: "4px" }}>
                                        <CButton handleClick={() => actionHandler("Approved")} name="Approve" disabled={disabled ? disabled : !comments} />
                                    </div>
                                    <div style={{ padding: "4px" }}>
                                        <CButton handleClick={() => actionHandler("Rejected")} name="Reject" disabled={disabled ? disabled : !comments} />
                                    </div>
                                </div>
                            </Grid> */}
                        </Paper>
                    </AccordionDetails>
                </Accordion>

            </DialogContent>
            {
                showPDFView &&
                <UserDataPDF open={showPDFView} setOpen={setShowPDFView} pdf={pdf} setPdf={setPdf}/>
            }

        </React.Fragment>
    );
}
